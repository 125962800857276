import React from 'react';
import { getAppUrl, getBlogUrl, getContentSiteUrl, getMonolithUrl, getRuntimeType, getSubmissionUrl } from 'src/config';
import cookie from 'js-cookie'
import SellerPageHeader, {
    contactUsFactory,
    LinkItem,
    loggedOutMenuItemsFactory,
    menuItemsFactory,
} from '@worthy-npm/worthy-common-ui-components/components/SellerPageHeader';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getItemCount, getUser } from 'src/store/userSlice';
import { useLocation } from 'react-router-dom';
import { setRoute } from 'src/store/sharedSlice';
import { redirectTo } from 'src/helpers/common.ts';
import { Box, Button, Divider, Icon, ListItemIcon, ListItemText, MenuItem, styled } from '@worthy-npm/worthy-common-ui-components';
import { authService } from 'src/services/api/auth.service.ts';

const StyledMenuItems = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    '& .MuiListItemIcon-root': {
        color: theme.palette.text.primary,
    },
}));

async function logout(id: number) {
    const env = getRuntimeType();
    const envFixed = env.includes('local') ? 'development' : env;
    const jwtName = envFixed.includes('prod') ? 'jwt' : `${envFixed}_jwt`;
    const authToken = envFixed.includes('prod') ? 'sellerAuthToken' : `sellerAuthToken_${envFixed}`;

    cookie.set(`${envFixed}_bridge_apptosite_logged_out`, 'true', {
        domain: 'worthy.com',
        path: '/',
        expires: new Date('2100-01-01'),
    })
    cookie.remove(jwtName, { path: '/', domain: 'worthy.com' })
    cookie.remove(authToken, { path: '/', domain: 'worthy.com' })
    id && await authService.logout(id);
    redirectTo({url: getContentSiteUrl()});
}

function CustomLoggedInMenu() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(getUser);

    const logOut = () => {
        logout(user.userId || 0);
    }

    return (
        <Box p={1} color="text.primary" width={200}>
            <StyledMenuItems onClick={()=> {
                dispatch(setRoute('/my-items'));
            }}>
                <ListItemIcon>
                    <Icon.ShoppingBasket fontSize="small" />
                </ListItemIcon>
                <ListItemText>My items</ListItemText>
            </StyledMenuItems>
            <StyledMenuItems onClick={() => redirectTo({url: `${getAppUrl()}/refer_a_friend`})}>
                <ListItemIcon>
                    <Icon.Reward fontSize="small" />
                </ListItemIcon>
                <ListItemText>Refer a friend</ListItemText>
            </StyledMenuItems>
            <Divider />
            <StyledMenuItems>
                <ListItemIcon>
                    <Icon.LogOut fontSize="small" />
                </ListItemIcon>
                <ListItemText onClick={logOut}>Log out</ListItemText>
            </StyledMenuItems>
            <Box p={1}>
                <Button fullWidth variant="contained" color="highlight" size="small" disableElevation onClick={()=> redirectTo({url: getSubmissionUrl()})}>
                    Start selling
                </Button>
            </Box>
        </Box>
    );
}

const Header = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const user = useAppSelector(getUser);

    const itemsCount = useAppSelector(getItemCount);
    const contactUsData = contactUsFactory('(888) 222-0208');
    const loggedOutMenuItemsData = loggedOutMenuItemsFactory(getAppUrl());

    const loggedInMenuItemsData = [{
        key:'myItems-root',
        title:'My Items',
        link: '/my-items',
    },{
        key: 'refer-a-friend',
        title: 'Refer a Friend',
        link: getAppUrl()+'/refer_a_friend',
    },{
        key: 'logout',
        title: 'Logout',
        link: '/logout',}]
    const menuItemsData = menuItemsFactory({
        appBaseUrl: getAppUrl(),
        blogBaseUrl: getBlogUrl(),
        monolithBaseUrl: getMonolithUrl(),
    });

    const homeLinks: Record<string, string> = {
        howItWorks: `${getContentSiteUrl()}/how-it-works/`,
        home: getContentSiteUrl(),
    };

    const logOut = () => {
        logout(user.userId || 0);
    }

    const onItemClick = (e: React.SyntheticEvent, payload: { key: string } & Partial<LinkItem>) => {
        console.log(payload);
        const link = String(payload?.link);
        if (payload.key === 'menuOpen') {
            return;
        }

        if (payload.key.includes('root') && payload.link) {
            if (location.pathname === payload.link) return;
            dispatch(setRoute(payload.link));
            return;
        }

        if (payload.key === 'logout') {
            logOut();
            return;
        }

        if (Object.keys(homeLinks).includes(payload.key)) {
            redirectTo({url: homeLinks[payload.key]});
        } else{
            redirectTo({url: link});
        }
    };

    return (
        <SellerPageHeader
            contactUsData={contactUsData}
            loggedInMenuItemsData={loggedInMenuItemsData}
            loggedOutMenuItemsData={loggedOutMenuItemsData}
            menuItemsData={menuItemsData}
            loginState="loggedIn"
            itemsCount={itemsCount}
            onItemClick={onItemClick}
            disableFadeUp={true}
            CustomLoggedInMenu={CustomLoggedInMenu}
            appBarProps={{
                position: 'sticky',
                sx: {
                    color: worthyShineTheme.palette.text.primary,
                    backgroundColor: worthyShineTheme.palette.background.default,
                    boxShadow: `0px 1.5px 0px 0px ${worthyShineTheme.palette.divider}`,
                },
            }}
            itemsCountProps={{
                sx: {
                    display: 'none',
                },
            }}
        />
    );
};

export default Header;
