import { PropsWithChildren } from 'react';
import Header from 'src/layout/nav/header.tsx';
import { Box, Drawer, styled } from '@worthy-npm/worthy-common-ui-components';
import Navigation from 'src/layout/nav/navigation.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getSchedulingCurrentStep, isSchedulingOpen, setOpenScheduling } from 'src/store/schedulingSlice';
import SchedulingPopUp from 'src/components/SchedulingPopUp/SchedulingPopUp';
import Banner from 'src/components/banner/Banner.tsx';
import { useDesktopVersion } from 'src/helpers/deviceSize';
import { Dialog } from '@mui/material';


const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
    }));

const StyledModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius,
        minWidth: 360,
        minHeight: 460,
        backgroundColor: theme.palette.background.paper,
    }}));

interface BaseLayoutProps extends PropsWithChildren {
  allPending?: boolean;
}
const BaseLayout = ({ allPending = false, children }: BaseLayoutProps) => {
    const dispatch = useAppDispatch();
    const openReschedule = useAppSelector(isSchedulingOpen);
    const currentStep = useAppSelector(getSchedulingCurrentStep);
    const isDesktop = useDesktopVersion();

    return (
        <Box>
            <Banner />
            <Header />
            <Navigation />
            <Box>{ children }</Box>
            {
                isDesktop ?  <StyledModal
                open={openReschedule}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                onClose={ () => {
                    if (currentStep === 0) {
                        dispatch(setOpenScheduling(false));
                    }
                }}
              >
                <SchedulingPopUp isPC={ true } allPending={allPending} />
              </StyledModal>:
                <StyledDrawer
                    anchor="bottom"
                    open={ openReschedule }
                    onClose={ () => {
                        if (currentStep === 0) {
                            dispatch(setOpenScheduling(false));
                        }
                    } }>
                    <SchedulingPopUp isPC={ false } allPending={allPending} />
                </StyledDrawer>
            }
        </Box>
    );
};

export default BaseLayout;
