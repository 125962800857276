import dayjs from 'dayjs';
import { worthyService } from 'src/services/api/worthy.service.ts';
import { IMask } from 'react-imask';
import { DEFAULT_PHONE_NUMBERS } from 'src/constants/commonConstants.tsx';
import { ValueCategory, WorkflowStatus } from "src/constants/item.constants";
import { getRuntimeType } from 'src/config';
import { Item } from 'src/types/item.ts';
import cookie from 'js-cookie';

// if in service navigation use !setRoute!
const addUvidParam = (url: string): string => {
  const uvid = cookie.get('uvid');
  if (!uvid) return url;

  const urlObj = new URL(url); // eslint-disable-line
  urlObj.searchParams.append('uvid', uvid);
  return urlObj.toString();
};

export const redirectTo = ({url, skipUvid}: {url: string, skipUvid?: boolean}) => {

  window.location.replace(skipUvid ? url : addUvidParam(url));
};

export const windowOpenTo = ({url, target, features, skipUvid}: {url: string | URL, target?: string, features?: string,skipUvid?: boolean}) => {
  window.open(skipUvid ? url : addUvidParam(url.toString()), target, features);
};



export const sendUserEvent = (message: string, itemPublicId: string) => {
    worthyService
        .logUserAction(message, itemPublicId)
        .then((r) => {
            console.log('logUserAction: ', r);
        })
        .catch((e) => {
            console.error('logUserAction: ', e);
        });
};

export const isHighValueLeads = (item: Item | undefined) => {
    if (!item) return false;
    console.log('isHighValueLeads', item.valueCategory);
    return item.valueCategory?.toLowerCase().includes(ValueCategory.hv);
};

export const isUltraValueLeads = (item: Item | undefined) => {
    if (!item) return false;
    return item.valueCategory?.toLowerCase().includes(ValueCategory.ue)
};

export const isInsuranceApprovalRequired = (item: Item | undefined) => {
  if (!item) return false;
  return item.status === WorkflowStatus.INSURANCE_APPROVAL_REQUIRED
};

export const isSuperExpensiveLeads = (item: Item | undefined) => {
    if (!item) return false;
    return item.valueCategory?.toLowerCase().includes(ValueCategory.se)
};

export const isStandardLeads = (item: Item | undefined) => {
    if (!item) return false;
    return item.valueCategory?.toLowerCase().includes(ValueCategory.standard)
};

export const isWatchLeads = (item: Item | undefined) => {
    if (!item) return false;
    return item.itemType?.toLowerCase().includes('watch')
};

// export const isJBLeads = (item: Item | undefined) => {
//     if (!item) return false;
//     console.log('isJBLeads', item.valueCategory);
//     return item.valueCategory.toLowerCase().includes(ValueCategory.jb);
// };

export function isFakePhone(phone: string) {
    return DEFAULT_PHONE_NUMBERS.includes(phone);
}

export const normalizePhoneNumber = (value: string, mask = true) => {
    const masked = IMask.createMask({
        mask: '(#00) 000-0000',
        definitions: {
            '#': /[2-9]/,
        },
    });
    masked.resolve(value);
    return mask ? masked.value : masked.unmaskedValue;
};

export const getDayWithSuffix = (day: number): string => {
    if (day === 1 || day === 21 || day === 31) {
        return `${day}st`;
    } else if (day === 2 || day === 22) {
        return `${day}nd`;
    } else if (day === 3 || day === 23) {
        return `${day}rd`;
    } else {
        return `${day}th`;
    }
};

export const formatDateTimeRange = (startDate: string, endDate: string): string => {
  const startParsed = dayjs(startDate);
  const endParsed = dayjs(endDate);

  const startTime = startParsed.format('h:mmA'); // e.g., "1:00pm"
  const endTime = endParsed.format('h:mmA'); // e.g., "2:00pm"

  const month = startParsed.format('MMMM'); // Full month name, e.g., "September"
  const day = startParsed.date(); // Day of the month, e.g., 31
  const year = startParsed.format('YYYY'); // Year, e.g., 2024

  return `${startTime} - ${endTime} on ${month} ${day}, ${year}`;
};

export const formatDate = (date: string): string => {
  const parsed = dayjs(date);

  const month = parsed.format('MMMM'); // Full month name, e.g., "September"
  const day = parsed.date(); // Day of the month, e.g., 31
  const year = parsed.format('YYYY'); // Year, e.g., 2024

  return `${month} ${day}, ${year}`;
};

export const getMainFromSelected = (items: Item[]) => {
    const sortedItems = items.slice().sort((a, b) => {
        if (!a || !b) return 0;
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
    return sortedItems[0];
};

export const getMainItemByValueCategory = (items: Item[]) => {
    let mainItem = items.find((item) => isSuperExpensiveLeads(item));
    if (mainItem) return mainItem;
    mainItem = items.find((item) => isUltraValueLeads(item));
    if (mainItem) return mainItem;
    mainItem = items.find((item) => isHighValueLeads(item));
    if (mainItem) return mainItem;
    mainItem = items.find((item) => isStandardLeads(item));
    if (mainItem) return mainItem;
    mainItem = getMainFromSelected(items);
    return mainItem;
}

export const isDatePassed = (date: Date) => {
    const now = new Date();
    return date < now;
};

export const makeThumbnail = (url: string, width = 130) => {
    const imgEnv = getRuntimeType();
    if (imgEnv === 'production') {
        return `https://www.worthy.com/cdn-cgi/image/width=${width}/${url}`;
    }
    if (imgEnv === 'local') {
        return `https://demo.worthy.com/cdn-cgi/image/width=${width}/${url}`;
    }
    return `https://${imgEnv}.worthy.com/cdn-cgi/image/width=${width}/${url}`;
};
