import { getBackendUrl } from 'src/config';
import axios, { AxiosInstance } from 'axios';
import { Item, SchedulingDetails } from 'src/types/item.ts';
import { NotificationI } from "src/types/notification.ts";
import { SchedulingType } from 'src/constants/item.constants.tsx';
import _ from 'lodash';

export interface Profile {
  userId: number;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  address: string;
  itemsCount: number;
  createdAt: string;
}

interface IShippingPickupData {
  ready_timestamp: string;
  company_close_time: string;
  drop_off_locations: Record<number, { name: string; address: string }>;
}

interface IShippingDropOffData {
  itemPublicId: string;
  drop_off_locations: Record<number, { name: string; address: string }>;
}

class BackendService {
  private backend: AxiosInstance;

  constructor() {
    this.backend = axios.create({
      baseURL: getBackendUrl(),
      withCredentials: true,
    });
  }

  async fetchUserItems(): Promise<Item[] | []> {
    try {
      const { data } = await this.backend.get<Item[] | []>(`api/v1/items`);
      return data;
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  }

  async fetchNotifications(): Promise<NotificationI[] | []> {
    try {
      const { data } = await this.backend.get<NotificationI[] | []>(`api/v1/notifications`);
      return data;
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  }

  async markNotificationAsRead(notificationId: string): Promise<void> {
    try {
      await this.backend.post<void>(`/api/v1/notification/${notificationId}/markAsRead`);
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  }

  async fetchUserProfile(): Promise<Profile | Partial<Profile>> {
    try {
      const { data } = await this.backend.get<Profile | Partial<Profile>>(`api/v1/profile`);
      return data;
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  }

  async getShippingLabelWithInsurance(publicId: string): Promise<ArrayBuffer> {
    try {
      const response = await this.backend.get<ArrayBuffer>(`/items/${publicId}/shipping_label_and_insurance`, {
        responseType: 'arraybuffer',
      });
      return response.data;
    } catch (err) {
      throw new Error(JSON.stringify(err));
    }
  }

    async scheduleShipping(
        itemId: string,
        shippingType: SchedulingType.PICKUP | SchedulingType.DROPOFF,
        itemIds: string[],
        data: IShippingPickupData | IShippingDropOffData,
    ): Promise<SchedulingDetails> {
        _.set(data, 'expectFullResponse', true);

        try {
            const resp = await this.backend.post<SchedulingDetails>(`/api/v1/schedule_shipping`, {
                shippingType,
                publicId: itemId,
                itemIds,
                params: data,
            });

            return resp.data;
        } catch (err) {
            throw new Error(JSON.stringify(err));
        }
    }
}

export const backendService = new BackendService();
