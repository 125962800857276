import {  Icon, Stack, Typography } from "@worthy-npm/worthy-common-ui-components";
import { HIWProps } from "../AccordionStepper/AccordionStepper";

interface DesktopStepperProps {
    steps: HIWProps['steps'];
    title: HIWProps['title'];
}

const DesktopStepper = ({steps, title}: DesktopStepperProps) => {
  return (<Stack direction={'row'} position={'relative'} justifyContent={'space-between'}>
    <Stack justifyContent={'space-around'}>
        <Typography p={2} pl={0} variant="h3" textAlign={'center'} maxWidth={200} margin={'0 auto'}>{title}</Typography>
    </Stack>
    <Stack direction={'row'} gap={2} alignItems={'start'}>
        {steps.map((step, index) => (
            <Stack key={index} gap={1} maxWidth={150}>
                <Stack direction={'row'} alignItems={'flex-end'} >
                    <Typography variant="h3">{index + 1}</Typography>
                    {
                        index !== steps.length - 1 && (
                            <Typography sx={{margin: '0 auto', color: 'tertiary.main'}}>
                                <Icon.NextArrowIcon sx={{width: '3rem'}} color="inherit"  />
                            </Typography>
                        )
                    }
                    
                </Stack>
                <Stack  direction={'column'} gap={1}>
                    <Typography variant="body1" fontWeight={'bold'}>{step.title}</Typography>
                    <Typography variant="body1" >{step.description}</Typography>
                </Stack>
            </Stack>
        ))}
    </Stack>
    {/* {onClose && <Box position={'absolute'} top={(theme)=> theme.spacing(-1)} right={(theme)=> theme.spacing(-1)}>
        <IconButton size="small" color="secondary" onClick={onClose}>
                <Icon.CloseIcon fontSize={'small'} color="inherit" />
        </IconButton>
    </Box>} */}
  </Stack>);
};
export default DesktopStepper;
