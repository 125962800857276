import { useEffect, useMemo, useState } from 'react';
import { isItemPreScheduling } from 'src/helpers/scheduling/item.ts';
import { Item } from 'src/types/item.ts';
import { groupBy } from 'lodash';
import { ItemGroups } from "src/constants/itemCard.constants.tsx";
import { preScheduleStates, WorkflowStatus } from "src/constants/item.constants.tsx";

export const usePreSchedulingItems = (items: Item[] | []) => {
  const [preSchedulingItems, setPreSchedulingItems] = useState<Item[]>([]);

  useEffect(() => {
    if (items.length > 0) {

      // if we have at least one valid item we should allow scheduling for all items except ultra
      const haveValidItem = items.some((item) => {
        return item.status === WorkflowStatus.SCHEDULING;
      });

      const canConsiderAsValid = ((item: Item) => {
        return item.status === WorkflowStatus.SCHEDULING ||
          (haveValidItem && item.secondary && item.status === WorkflowStatus.PENDING)
      });

      const filteredItems = items
        .filter((item) => isItemPreScheduling(item.status))
        .map((item) => (Object.freeze({
          ...item,
          group: preScheduleStates.includes(item.status)
            ? canConsiderAsValid(item) ? ItemGroups.Valid : ItemGroups.Pending
            : null,
        }) as Item ));

      setPreSchedulingItems(filteredItems);
    }
  }, [items]);

  return useMemo(() => {
    return groupBy(preSchedulingItems, (item) =>
      item.group === ItemGroups.Valid ? ItemGroups.Valid : ItemGroups.Pending,
    );
  }, [preSchedulingItems]);
};
