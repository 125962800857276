import {
  Box,
  Paper,
  Stack,
  styled,
  Typography,
} from '@worthy-npm/worthy-common-ui-components';
import ExpandMoreShineIcon from '@worthy-npm/worthy-common-ui-components/components/Icon/ExpandMoreShine';

const HiwWrapper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '24px',
  padding: '40px 80px',
  backgroundColor: theme.palette.background.secondary,
  margin: '16px auto',
  maxWidth: '1200px',
  gap: '32px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: '24px 14px',
    backgroundColor: theme.palette.background.default,
    gap: theme.spacing(3),
    margin: 0,
  },
}));

// todo duplicated code
const StyledExpandMoreIcon = styled(ExpandMoreShineIcon, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const HiwStep = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  maxWidth: '189px',
}));

const HiwStepTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px !important',
  color: theme.palette.background.paper,
  marginBottom: '8px',
}));

const HiwImgContent = styled(Box, {
  shouldForwardProp: (prop) => !['bgImage', 'darken', 'highlight'].includes(prop as string),
})<{ bgImage?: string, darken?: boolean, highlight?: boolean }>(({ theme, bgImage, darken, highlight }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  backgroundImage: `url(${bgImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  alignSelf: 'center',
  width: '188px',
  height: '148px',
  borderRadius: '8px',
  padding: theme.spacing(2),
  color: theme.palette.background.paper,

  ...(darken && {
    backgroundImage: `radial-gradient(63.56% 185.43% at 72.25% 43.92%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(${bgImage})`,
    color: theme.palette.secondary.light,
  }),

  ...(highlight && {
    backgroundImage: `radial-gradient(138.89% 229.93% at 96% 33.11%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 100%), url(${bgImage})`,
    filter: `drop-shadow(-19px 9mm 22mm ${theme.palette.tertiary.main})`,
    color: theme.palette.highlight.main,
  }),
}));

const HiwPcStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
  },
}));


export {
  HiwPcStack,
  HiwImgContent,
  HiwStepTitle,
  HiwStep,
  HiwWrapper,
  StyledExpandMoreIcon,
};
