import {
    Typography,
    Paper,
    Unstable_Grid2 as Grid,
    Divider,
    Stack,
    styled,
    Box,
} from '@worthy-npm/worthy-common-ui-components';
import { ProgreeBarOptions, StepInfoObject } from 'src/constants/itemCard.constants';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Item } from 'src/types/item.ts';
import { useDesktopVersion } from 'src/helpers/deviceSize';
import { ItemCardBundleList } from 'src/views/ItemCardBundleList';
import { useState } from 'react';
import BundleItemsListCarousel from 'src/views/BundleItemsListCarousel';

export const NUMBER_OF_ITEMS_BEFORE_EXPAND = 2;

interface ItemCardProps {
    items: Item[];
    onCheckboxChange?: (item: Item, isChecked: boolean) => void;
}


const StyledContainer = styled(Paper)(({ theme }) => ({
    margin: theme.spacing(2),
    marginTop: 0,
    padding: theme.spacing(1.5, 2),
    borderRadius: '8px',
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
        maxWidth: 948,
        margin: 0,
        padding: theme.spacing(2),
    },
    width: '100%',
}));




function ItemCard({ items, onCheckboxChange }: ItemCardProps) {
    const { steps, color, title, currentStepIndex, alert } = ProgreeBarOptions({ item: items[0] });
    const { ActionComponent, title: stepTitle, ActionMenuComponent, ActionTriggeredComponent } = StepInfoObject({ items });
    const [showActionTriggered, setShowActionTriggered] = useState(false);
    const isDesktop = useDesktopVersion();
    const isWithProgressBar = steps.length > 0;
    const isDesktopMultipleItems = items.length > 1 && isDesktop && isWithProgressBar;

    const onActionTriggeredClose = () => {
        setShowActionTriggered(false);
    }

    const onActionShow = () => {
        setShowActionTriggered(true);
    }

    return (
        <StyledContainer>
            <Grid
                container
                rowSpacing={{xs: 1.5, md: 2}} columnSpacing={2}
            >
                <Grid container xs={12} md={5} gap={0.5}>
            {title && (
                <>
                <Grid  xs={12} height={25} >
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography
                                                variant="body1"
                                                color={color}
                                                fontWeight={'medium'}
                                                fontSize={'0.8rem'}
                                            >
                                                {title}
                                            </Typography>
                        { items.length > NUMBER_OF_ITEMS_BEFORE_EXPAND && !isDesktop && (
                            <Typography fontWeight={'medium'}
                            fontSize={'0.8rem'}>{items.length} items</Typography>
                        )
                        }

                    </Stack>
                </Grid>
                {!isDesktop &&  <Grid  xs={12}>
                    <Divider  />
                    </Grid>
                    }
                </>
            )}
            {isDesktopMultipleItems && (
                <Grid xs={12}>
                    <ProgressBar
                        color={color}
                        progressIndex={currentStepIndex}
                        steps={steps}
                        alert={alert}
                    />
                </Grid>
            )}
            {
                !isDesktopMultipleItems && (
                    <Grid>
                        <ItemCardBundleList items={items} onCheckboxChange={onCheckboxChange} />
                    </Grid>
                )
            }
                </Grid>
                {isWithProgressBar && (
                    <>
                        { !isDesktop && <Grid  xs={12}>
                            <Divider  />
                            </Grid>
                         }
                        <Grid xs={12} md={7}>
                            <Stack height={'100%'} justifyContent={'start'} gap={{xs: 1.5, md: 1}}>

                        {stepTitle && 
                            <Stack
                                        height={25}
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                    >
                                        <Typography
                                            variant="body1"
                                            fontWeight={'bold'}
                                            color={color}
                                        >
                                            {stepTitle}
                                        </Typography>
                                        {ActionMenuComponent && <ActionMenuComponent items={items} />}
                                    </Stack>
                            }
                                {ActionComponent && <Box height={{md: 'inherit'}}><ActionComponent item={items[0]} onActionShow={onActionShow} /></Box>}
                                {
                                    !isDesktopMultipleItems && (
                                        <Box height={60} order={{xs: -1, md: 0}}>
                                            <ProgressBar
                                                color={color}
                                                progressIndex={currentStepIndex}
                                                steps={steps}
                                                alert={alert}
                                            />
                                        </Box>
                                    )
                                }
                            </Stack>
                        </Grid>
                    </>
                )}
            {ActionTriggeredComponent && showActionTriggered && <Grid xs={12}>
                <ActionTriggeredComponent item={items[0]} onClose={onActionTriggeredClose} />
            </Grid>}
            { isDesktopMultipleItems && (<>
            <Grid xs={12}>
                <Divider />
            </Grid>
            <Grid xs={12}>
                <BundleItemsListCarousel items={items} />
            </Grid>
            </>
        )}
            </Grid>
        </StyledContainer>
    );
}

export default ItemCard;
