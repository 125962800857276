/* eslint-disable @typescript-eslint/no-misused-promises */

import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    Checkbox,
    CircularProgress, Stack, TextField, Typography,
} from '@worthy-npm/worthy-common-ui-components';
import { Icon } from 'src/components/Icons.tsx';
import { BottomFixedCtaBtn } from 'src/styles/SchedulingCTA/SchedulingCTA.styles.tsx';
import {
    FormContainer,
    FormWrapper,
    RadioWrapper,
    TextInput,
    FormControl,
} from 'src/components/AddressInput/AddressInput.styles.tsx';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
    getApt,
    getPhoneNumber,
    nextStep,
    setSchedulingPhoneNumber,
    setSchedulingApt,
    getPrivateResidence,
    setSchedulingPrivateResidence,
    getMainItemPublicId,
    getFormattedAddress,
    getSelectedShippingMethod,
    setDropOffLocation,
    createShipping,
    setLoading,
    getError,
    setError,
    setShippingMethod, setFlowToStart, getLocations, getSelectedItems, resetShippingState, closeScheduling, setReset,
    getShipmentAddress,
    setShipmentAddress,
    getShowZipcodeInput,
} from 'src/store/schedulingSlice';
import { getUser, isUserMissingPhoneNumber } from 'src/store/userSlice';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { GenerateShippingParams, worthyService } from 'src/services/api/worthy.service.ts';
import {  addressWithoutApt, addressToObject, prepareDropOffLocations } from 'src/helpers/scheduling/common.ts';
import { normalizePhoneNumber, sendUserEvent } from 'src/helpers/common.ts';
import Ga from 'src/services/ga.service/ga.ts';
import { SchedulingType } from 'src/constants/item.constants.tsx';
import { LocationType } from 'src/types/shipping.ts';
import SchedulingHeader from 'src/components/SchedulingPopUp/schedulingHeader.tsx';
import { NoSlotsButton, NoSlotsContainer, NoSlotsWrapper } from 'src/components/PickupSlots/PickupSlots.styles.tsx';
import { createTempShippingItem, scheduleDropOff } from 'src/store/itemsSlice';
import { setRoute } from 'src/store/sharedSlice';
import AutoCompleteAddress from './AutoCompleteAddress';
import { addOfflineScheduledNotification } from 'src/store/notificationsSlice';
import { getCityAndStateFromZipcode, initializeGoogleMapsServices } from './googleMapsHelper';


const AddressInput = ({ isPC }: { isPC: boolean }) => {
    const dispatch = useAppDispatch();

    const shipmentMethod = useAppSelector(getSelectedShippingMethod);
    const userMissingPhoneNumber = useAppSelector(isUserMissingPhoneNumber);
    const user = useAppSelector(getUser);
    const mainItemPublicId = useAppSelector(getMainItemPublicId);
    const selectedScheduled = useAppSelector(getSelectedItems);
    const isError = useAppSelector(getError);
    const shipmentLocations = useAppSelector(getLocations);
    const formattedAddress = useAppSelector(getFormattedAddress);
    const shipmentAddress = useAppSelector(getShipmentAddress);

    const [ apt, setApt ] = useState(useAppSelector(getApt));
    const [ privateResidence, setPrivateResidence ] = useState(useAppSelector(getPrivateResidence));
    const [ phoneNumber, setPhoneNumber ] = useState(useAppSelector(getPhoneNumber));
    const [ phoneError, setPhoneError ] = useState('');
    const [ loadingForm, setLoadingForm ] = useState(false)
    const showZipcodeInput = useAppSelector(getShowZipcodeInput)
    const [zipcode, setZipcode] = useState('');
    const [zipcodeError, setZipcodeError] = useState('');
    const [ geocoderService, setGeocoderService ] = useState<google.maps.Geocoder | null>(
        null,
    );
  
    const validateZipcode = (value: string) => {
      const zipcodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      if (!zipcodeRegex.test(value)) {
        setZipcodeError('Invalid zipcode format');
      } else {
        setZipcodeError('');
      }
    };
    const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setZipcode(value);
      validateZipcode(value);
    };

    const validateForm = useCallback(() => {
        let valid = true;

        if (userMissingPhoneNumber && (!phoneNumber || !isValidPhoneNumber(phoneNumber, 'US'))) {
            setPhoneError('Please enter a valid phone number');
            valid = false;
        } else {
            setPhoneError('');
        }

        return valid;
    }, [ userMissingPhoneNumber, phoneNumber ]);

    useEffect(() => {
            const { geocoder } = initializeGoogleMapsServices();
            setGeocoderService(geocoder);
        }, []);

    const resetErrors = () => {
        dispatch(setError(''));
        dispatch(setLoading(false));
    };

    const setToDropOff = () => {
        dispatch(setShippingMethod(SchedulingType.DROPOFF));
        dispatch(setFlowToStart());
        resetErrors();
    };

    const handleAptChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!isNaN(Number(value))) setApt(value);
    }, []);


    const generateShipping = useCallback(async () => {
        let locations: LocationType[] = [];
        const isAddressChange = !(addressWithoutApt(shipmentAddress) === formattedAddress)
        const {street, city, state, zipcode: formattedZipcode} = addressToObject(formattedAddress);
        const finalZipcode = formattedZipcode || zipcode;
        const addressObj = {
            street: street,
            street2: apt,
            city: city,
            state: state,
            zipcode: finalZipcode,
        };
        //update state/city if it missing from zipcode
        if((!addressObj.city || !addressObj.state) && finalZipcode && geocoderService ){
            await getCityAndStateFromZipcode(
                geocoderService,
                finalZipcode
            ).then((result) => {
                if(result){
                    addressObj.city = result.city;
                    addressObj.state = result.state;
                }
            }).catch((err) => {
                console.error('Error getting city and state from zipcode:', err);
            });
        }

        const userObj = {
            first_name: user.firstName ?? '',
            last_name: user.lastName ?? '',
        };
        const generateShippingParams: GenerateShippingParams = {
            itemPublicIds: selectedScheduled,
            addressObj,
            user: userObj,
        };

        if(shipmentLocations.length > 0 && !isAddressChange){
            return shipmentLocations;
        }
        

        try {
            const { item } = await dispatch(createShipping(generateShippingParams)).unwrap();
            const shipment = item.shipment;
            locations = shipment.locations;
            dispatch(setShipmentAddress({
                street: addressObj.street,
                apt: addressObj.street2,
                city: addressObj.city,
                state: addressObj.state,
                zipcode: addressObj.zipcode,
            }));
        } catch (err) {
            sendUserEvent('Pickup/Drop-off modal presented', mainItemPublicId);
            dispatch(setError('Error generating shipping'));
            dispatch(setLoading(false));
            //TODO: present error to user
            console.error(err);
        }

        return locations;
    }, [dispatch,zipcode, apt, formattedAddress, mainItemPublicId, selectedScheduled, shipmentAddress, shipmentLocations, user]);

    const updatePhoneNumber = useCallback(async () => {
        dispatch(setSchedulingPhoneNumber(phoneNumber));
        sendUserEvent('Complete Phone number modal - clicked next', mainItemPublicId);
        await worthyService
            .updateUserPhoneNumber(phoneNumber, user.userId!)
            .then((status) => {
                console.log('phone updated status: ', status);
            })
            .catch((err) => {
                //TODO: present error to user
                console.error(err);
            })
    }, [dispatch, phoneNumber, user, mainItemPublicId]);

    const moveItem = useCallback((address: string, dropOffLocation: string) => {
        dispatch(setReset(true));
        dispatch(createTempShippingItem({ publicIds: selectedScheduled, shipType: shipmentMethod, address, dropOffLocations: [dropOffLocation] }));
        Ga.SendActionSuccess({
            actionedObject2: 'schedulingFunnel',
            actionedValue2: 'schedulingConfirmationStepView',
            actionedObject3: 'schedulingShipmentType',
            actionedValue3: 'fedexDropoff',
            actionedObject4: 'shippingOptionsScheduled',
            actionedValue4: 'dropoffScheduled',
            location1: 'sellerDashboard',
            location2: 'shippingScreen',
            location3: 'shippingOptionsComponent',
            location4: 'schedulingConfirmation',
        });
        dispatch(resetShippingState());
        dispatch(closeScheduling());
        dispatch(setRoute('/my-items'));
    },[ dispatch, selectedScheduled, shipmentMethod]);

    const handleDropOffScheduled = useCallback(async (shipmentLocations: LocationType[]) => {
        try {
            sendUserEvent('Finish drop-off button clicked', mainItemPublicId);

            const locations = await prepareDropOffLocations(shipmentLocations);

            const data = {
                itemPublicId: mainItemPublicId,
                dropOffLocations: locations,
                itemIds: selectedScheduled,
            };

            dispatch(scheduleDropOff(data));

            const location = `${locations[0].name} ${locations[0].address}`;
            dispatch(setDropOffLocation(location));
            moveItem(formattedAddress, location);

            dispatch(addOfflineScheduledNotification(SchedulingType.DROPOFF));
        } catch (error) {
            console.error('Error handleDropOffScheduled:', error);
            dispatch(setError('Error scheduling drop off'));
            throw error;
        }
    },[dispatch,formattedAddress, mainItemPublicId, moveItem]);

    const onNextClick = useCallback(async () => {
        if (!validateForm()) return;

        try {
            setLoadingForm(true);

            dispatch(setSchedulingApt(apt));
            dispatch(setSchedulingPrivateResidence(privateResidence));

            if (phoneNumber) await updatePhoneNumber();
            const shipmentLocations = await generateShipping();

            sendUserEvent('Next button clicked - address provided', mainItemPublicId);

            if (shipmentMethod === SchedulingType.DROPOFF) {
                await handleDropOffScheduled(shipmentLocations);
            }

            dispatch(nextStep());
        } catch (error) {
            setLoadingForm(false);
            //TODO: add error handling
            console.error(error);
        } finally {
            dispatch(setLoading(false));
            setLoadingForm(false);
        }
    }, [
        mainItemPublicId,
        shipmentMethod,
        validateForm,
        apt,
        privateResidence,
        phoneNumber,
        updatePhoneNumber,
        generateShipping,
        dispatch,
        handleDropOffScheduled,
    ]);

    const handlePrivateResidenceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPrivateResidence(e.target.checked);
        setApt('');
    };

    const getPhoneField = () => {
        if (userMissingPhoneNumber) {
            return (
                <TextInput
                    key="phone-number"
                    id="phone-number"
                    fullWidth
                    label="Phone Number"
                    autoFocus
                    error={ !!phoneError }
                    helperText={ phoneError }
                    InputLabelProps={ { style: {color: '#7A8994',
                        paddingLeft: '16px'} } }
                    data-automation="phone-input"
                    variant="standard"
                    value={ phoneNumber }
                    placeholder="(423) 456-7890"
                    onChange={ (e) => {
                        const formattedValue = normalizePhoneNumber(e.target.value);
                        setPhoneNumber(formattedValue);
                    } }
                />
            );
        }

        return null;
    };

    const getPrivateResidenceField = () => (
        <RadioWrapper>
            <FormControl
                label="its a private residence"
                data-automation="private-residence"
                id="private-residence"

                control={
                    <Checkbox
                        size="small"
                        icon={
                            <Icon.RoundCheckBoxIcon
                                sx={ {
                                    width: '14px',
                                    height: '14px',
                                } }
                            />
                        }
                        checkedIcon={
                            <Icon.RoundCheckBoxFilledIcon
                                sx={ {
                                    width: '14px',
                                    height: '14px',
                                } }
                            />
                        }
                        checked={ privateResidence }
                        onChange={ handlePrivateResidenceChange }
                    />
                }
            />
        </RadioWrapper>
    );

    const getSomethingWrong = () => (
        <NoSlotsWrapper>
            <NoSlotsContainer>
                <Typography variant="body1" textAlign="center">
                    There seems to be an issue scheduling a { shipmentMethod } at the provided address.
                </Typography>

                <NoSlotsButton
                    variant="contained"
                    disableElevation
                    onClick={ onNextClick }
                    size='large'
                >
                    Try Again
                </NoSlotsButton>
                {
                    shipmentMethod !== SchedulingType.DROPOFF && (
                        <>
                            <Typography variant="body1" color="divider">or</Typography>

                            <NoSlotsButton
                                variant="contained"
                                disableElevation
                                size='large'
                                onClick={ setToDropOff }
                            >
                                Drop-Off Item Instead
                            </NoSlotsButton>
                        </>
                    )
                }

            </NoSlotsContainer>
        </NoSlotsWrapper>
    );

    const getForm = () => (
        <FormContainer>
            <FormWrapper>
                { getPhoneField() }
                <AutoCompleteAddress />
                {showZipcodeInput  && <TextField
                    variant='standard'
                    label='Zipcode'
                    fullWidth
                    value={zipcode}
                    onChange={handleZipcodeChange}
                    error={!!zipcodeError}
                    helperText={zipcodeError}
                    InputLabelProps={{
                    sx: {
                        paddingLeft: 2,
                        color: 'secondary.main',
                    },
                    }}
                    />}
                <TextInput
                    id="address-apt"
                    fullWidth
                    label="Apartment / suits No."
                    InputLabelProps={{
                        sx: {
                            paddingLeft: 2,
                            color: 'secondary.main',
                        },
                        }}
                    data-automation="apt-input"
                    variant="standard"
                    value={ apt }
                    disabled={ privateResidence }
                    onChange={ handleAptChange }
                />
            </FormWrapper>
            { getPrivateResidenceField() }
        </FormContainer>
    );

    return (
        <>
            <SchedulingHeader isPC={ isPC } title="Enter Information" />

            <Stack gap={3}>

                { !isError ? getForm() : getSomethingWrong() }

                <BottomFixedCtaBtn
                    variant="contained"
                    color="primary"
                    data-automation="shipping-confirm"
                    sx={ {
                        marginTop: 'auto',
                        flex: '0 1 0',
                        alignSelf: 'stretch',
                        fontSize: '18px',
                        color: '#FFF !important',
                    } }
                    onClick={ () => void onNextClick() }
                    disableElevation
                    size="large"
                    fullWidth
                    disabled={ (showZipcodeInput && (!!zipcodeError || !zipcode)) || !formattedAddress || (!apt && !privateResidence) || loadingForm || !!isError }
                    >
                    { loadingForm ? <CircularProgress size={ 24 } /> : 'Confirm Address' }
                </BottomFixedCtaBtn>
            </Stack>
        </>
    );
};

export default AddressInput;
